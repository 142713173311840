import { FlowComponent, JSX, Show } from "solid-js";
import { Tooltip as KTooltip } from "@kobalte/core/tooltip";

import "./tooltip.css";
import { isServer } from "solid-js/web";

type TooltipProps = {
  children: any;
  content: JSX.Element | string;
  class?: string;
  placement?: "top" | "bottom";
  openDelay?: number;
};

export const Tooltip: FlowComponent<TooltipProps> = (props) => {
  const contentClass = () => {
    const base = "tooltip__content z-50 max-w-[calc(100vw-16px),_380px]";
    const style = "bg-black text-white text-xs shadow-lg p-2 rounded-md border";
    return `${base} ${style} ${props.class}`;
  };
  // return props.children;
  return (
    <KTooltip
      placement={props.placement ?? "top"}
      openDelay={props.openDelay ?? 500}
    >
      <KTooltip.Trigger>{props.children}</KTooltip.Trigger>
      <KTooltip.Portal>
        <KTooltip.Content class={contentClass()}>
          <KTooltip.Arrow />
          {props.content}
        </KTooltip.Content>
      </KTooltip.Portal>
    </KTooltip>
  );
};
