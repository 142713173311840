import { Component, Show } from "solid-js";
import { Img } from "../image";
import { Tooltip } from "../ui";
import { PT, PTLabels } from "~/utils/products";

import length1 from "~/assets/icons/orderTypes/length1.svg";
import contract1 from "~/assets/icons/orderTypes/contract1.svg";
import join1 from "~/assets/icons/orderTypes/join1.svg";
import chop1 from "~/assets/icons/orderTypes/chop1.svg";
import rail1 from "~/assets/icons/orderTypes/rail1.svg";
import cornerSample1 from "~/assets/icons/orderTypes/cornerSample1.svg";
import mirror2 from "~/assets/icons/orderTypes/mirror2.svg";
import box1 from "~/assets/icons/orderTypes/box1.svg";
import photoFrame2 from "~/assets/icons/orderTypes/photoFrame2.svg";
import galleryFrame2 from "~/assets/icons/orderTypes/galleryFrame2.svg";

type ProductTypeIconProps = {
  productType: PT;
  enableTooltip?: boolean;
  size?: number;
  containerClass?: string;
};

export const ProductTypeIcon: Component<ProductTypeIconProps> = (props) => {
  const width = () => props.size || 28;

  const iconMapping: Record<string, string> = {
    [PT.LENGTH]: length1,
    [PT.JOIN]: join1,
    [PT.CHOP]: chop1,
    [PT.RAIL]: rail1,
    [PT.CONTRACT]: contract1,
    [PT.MIRROR]: mirror2,
    [PT.BOX]: box1,
    [PT.PHOTOFRAME]: photoFrame2,
    [PT.GALLERYFRAME]: galleryFrame2,
    [PT.CORNERSAMPLE]: cornerSample1,
  };

  return (
    <>
      <Show when={iconMapping[props.productType]}>
        <Show
          when={props.enableTooltip}
          fallback={
            <div
              class={`aspect-square ${props.containerClass ?? ""}`}
              style={{ width: `${width()}px` }}
            >
              <Img
                src={iconMapping[props.productType]}
                width={width()}
                height={width()}
                class="w-full"
                alt={PTLabels[props.productType]}
              />
            </div>
          }
        >
          <Tooltip content={PTLabels[props.productType]} openDelay={200}>
            <div
              class={`cursor-default aspect-square ${
                props.containerClass ?? ""
              }`}
              style={{ width: `${width()}px` }}
            >
              <Img
                src={iconMapping[props.productType]}
                width={width()}
                height={width()}
                class="w-full"
                alt={PTLabels[props.productType]}
              />
            </div>
          </Tooltip>
        </Show>
      </Show>
    </>
  );
};
